import { HeadingSlantedMedium } from '/features/buildingBlocks/Heading'
import { ImageCover } from '/features/buildingBlocks/Image'

import styles from './Hero.css'

export function Hero({ image = undefined, title }) {
  return (
    <div className={styles.component}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <HeadingSlantedMedium h={1} layoutClassName={styles.title} {...{ title }} />
          <div className={styles.heroShape} />
          {image?.asset && (
            <>
              <ImageCover
                aspectRatio={7 / 5}
                sizes='min(768px, calc(100vw - 40px))'
                {...{ image }}
                layoutClassName={styles.coverImage}
              />
              <ImageCover
                aspectRatio={3 / 4}
                sizes='min(768px, calc(100vw - 40px))'
                {...{ image }}
                layoutClassName={styles.coverImageMobile}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}
